import React, { useState } from 'react';

import { Wrapper } from '../../utils/components';
import Spinner from '../../atoms/spinner/spinner';
import ContenutoHtmlInner from '../../molecules/helpers-contenuto-html/ContenutoHtmlInner';
import PhysicalPersonForm from './PhysicalPersonForm';
import LegalPersonForm from './LegalPersonForm';
import { personTypes } from './helpers';
import SelectForm from './SelectForm';
import Infomessage from '../../molecules/infomessage/Infomessage';

import './AssistanceForm.scss';

const AssistanceForm = ({
  formType = '',
  title = '',
  subtitle = '',
  formTypeIntroduction = '',
  formTypeDescription = '',
  privacyPolicyDocumentUrl = '',
}) => {
  const [personType, setPersonType] = useState(personTypes.PHYSICAL_PERSON);
  const [showInfoMessage, setShowInfoMessage] = useState('');
  const [dumpedFormState, setDumpedFormState] = useState({});
  const [spinner, setSpinner] = useState(false);

  return (
    <Wrapper
      data-module-name='Assistance Form'
      data-module-code='C-105'
      data-form-id={formType}
      className='assistance-form wrapper wrapper--underflow module-top-gap--sm'
    >
      <div className='assistance-form__introduction'>
        {title && <h1 className='assistance-form__title'>{title}</h1>}
        {subtitle && (
          <div className='assistance-form__subtitle'>
            <ContenutoHtmlInner rawContent={subtitle} />
          </div>
        )}
      </div>
      <div className='assistance-form__description'>
        <span className='assistance-form__description-intro'>
          {formTypeIntroduction}
        </span>
        <h2 className='assistance-form__description-form-type'>
          {formTypeDescription}
        </h2>
      </div>
      {showInfoMessage === 'error' ? (
        <Infomessage
          type='error'
          text='Ci spiace, la tua richiesta di assistenza non è andata a buon fine.'
          className='assistance-form__infomessage'
          showButton={true}
          buttonText='Riprova'
          onClick={() => {
            setShowInfoMessage(false);
          }}
        />
      ) : showInfoMessage === 'success' ? (
        <Infomessage
          text='La richiesta è stata inoltrata con successo'
          buttonText={'Nuova richiesta'}
          type='success'
          className='assistance-form__infomessage'
          showButton={true}
          onClick={() => {
            setShowInfoMessage(false);
            setDumpedFormState({});
          }}
        />
      ) : null}
      {spinner ? (
        <Spinner className={'assistance-form__spinner'} />
      ) : (
        !showInfoMessage && (
          <div className='assistance-form__fields'>
            <SelectForm
              personType={personType}
              setPersonType={setPersonType}
              setDumpedFormState={setDumpedFormState}
            />
            <div className='assistance-form__form-wrapper'>
              {personType === personTypes.PHYSICAL_PERSON ? (
                <PhysicalPersonForm
                  setSpinner={setSpinner}
                  defaultState={dumpedFormState}
                  setDumpedFormState={setDumpedFormState}
                  setShowInfoMessage={setShowInfoMessage}
                  formType={formType}
                  privacyPolicyDocumentUrl={privacyPolicyDocumentUrl}
                />
              ) : (
                <LegalPersonForm
                  setSpinner={setSpinner}
                  defaultState={dumpedFormState}
                  setDumpedFormState={setDumpedFormState}
                  setShowInfoMessage={setShowInfoMessage}
                  formType={formType}
                  privacyPolicyDocumentUrl={privacyPolicyDocumentUrl}
                />
              )}
            </div>
          </div>
        )
      )}
    </Wrapper>
  );
};

export default AssistanceForm;
