import React from 'react';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { partitaIvaRegexp } from '../../utils/regexp';
import FormCommonFields from './FormCommonFields';
import {
  SubmitInput,
  generaliFormFields,
  AssistanceFormFieldError,
  personTypes,
  withSubmit,
} from './helpers';

const LegalPersonForm = ({
  formType = '',
  privacyPolicyDocumentUrl = '',
  captcha,
  setCaptcha,
  onSubmit,
  defaultState,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'all',
    defaultValues: defaultState,
  });

  return (
    <form
      className='assistance-form__form assistance-form__legal-person'
      onSubmit={handleSubmit((data) => onSubmit(data, 'Persona giuridica'))}
    >
      <div className='assistance-form__field assistance-form__business-name'>
        <label
          className='assistance-form__field-label'
          htmlFor={generaliFormFields.Gen_RagSoc}
        >
          Ragione sociale*
        </label>
        <input
          required
          placeholder='Ragione sociale'
          className={classNames('assistance-form__field-input', {
            'assistance-form__field-input--invalid':
              errors[generaliFormFields.Gen_RagSoc]?.message,
          })}
          id={generaliFormFields.Gen_RagSoc}
          {...register(generaliFormFields.Gen_RagSoc, {
            required: 'Campo obbligatorio',
          })}
        ></input>
        <AssistanceFormFieldError
          message={errors[generaliFormFields.Gen_RagSoc]?.message}
        />
      </div>
      <div className='assistance-form__field assistance-form__partita-iva'>
        <label
          className='assistance-form__field-label'
          htmlFor={generaliFormFields.Gen_PIva}
        >
          Partita IVA*
        </label>
        <input
          required
          minLength={11}
          maxLength={11}
          placeholder='Partita IVA'
          className={classNames('assistance-form__field-input', {
            'assistance-form__field-input--invalid':
              errors[generaliFormFields.Gen_PIva]?.message,
          })}
          id={generaliFormFields.Gen_PIva}
          {...register(generaliFormFields.Gen_PIva, {
            required: 'Campo obbligatorio',
            pattern: {
              value: partitaIvaRegexp,
              message: 'Inserisci un formato valido per la Partita IVA',
            },
          })}
        ></input>
        <AssistanceFormFieldError
          message={errors[generaliFormFields.Gen_PIva]?.message}
        />
      </div>
      <FormCommonFields
        personType={personTypes.LEGAL_PERSON}
        privacyPolicyDocumentUrl={privacyPolicyDocumentUrl}
        register={register}
        setCaptcha={setCaptcha}
        formControl={control}
        errors={errors}
        defaultProvinceValue={defaultState[generaliFormFields.Gen_Provincia]}
      />
      <SubmitInput disabled={!isValid || !captcha} htmlFor={'form-submit'} />
    </form>
  );
};

export default withSubmit(LegalPersonForm);
