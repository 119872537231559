import React, { useState } from 'react';
import { ButtonSubmit } from '../button/Button';
import parser from 'ua-parser-js';

const personTypes = Object.freeze({
  PHYSICAL_PERSON: 'physicalPerson',
  LEGAL_PERSON: 'legalPerson',
});

const browsersOptions = Object.freeze([
  { value: 'firefox', label: 'Firefox' },
  { value: 'chrome', label: 'Chrome' },
  { value: 'opera', label: 'Opera' },
  { value: 'edge', label: 'Edge' },
  { value: 'explorer', label: 'Internet Explorer' },
]);

const devicesOptions = Object.freeze([
  { value: 'pc', label: 'Pc' },
  { value: 'mac', label: 'MAC' },
  { value: 'smartphone-apple', label: 'Smartphone Apple' },
  { value: 'smartphone-android', label: 'Smartphone Android' },
  { value: 'tablet-apple', label: 'Tablet Apple' },
  { value: 'tablet-android', label: 'Tablet Android' },
]);

const generaliFormFields = Object.freeze({
  Gen_KEY: 'Gen_KEY',
  Gen_CodiceTipo: 'Gen_CodiceTipo',
  Gen_Nome: 'Gen_Nome',
  Gen_Cognome: 'Gen_Cognome',
  Gen_DtNascita: 'Gen_DtNascita',
  Gen_CodCliente: 'Gen_CodCliente',
  Gen_CodFiscale: 'Gen_CodFiscale',
  Gen_PIva: 'Gen_PIva',
  Gen_Telefono: 'Gen_Telefono',
  Gen_Citta: 'Gen_Citta',
  Gen_Indirizzo: 'Gen_Indirizzo',
  Gen_Email: 'Gen_Email',
  Gen_Provincia: 'Gen_Provincia',
  Gen_Cap: 'Gen_Cap',
  Gen_Info: 'Gen_Info',
  Gen_Browser: 'Gen_Browser',
  Gen_Dispositivo: 'Gen_Dispositivo',
  Gen_Stato: 'Gen_Stato',
  Gen_RagSoc: 'Gen_RagSoc',
  Gen_TipoCliente: 'Gen_TipoCliente',
  Gen_Privacy: 'Gen_Privacy',
  Gen_NumPolizza: 'Gen_NumPolizza',
  Gen_Sito: 'Gen_Sito',
  Gen_DtIns: 'Gen_DtIns',
  Gen_DClose: 'Gen_DClose',
});

/* Simple atomic input for submit, it's not under atoms because it's not generic enough */
const SubmitInput = (props) => (
  <div className={'assistance-form__submit'}>
    <ButtonSubmit
      text='Richiedi assistenza'
      inputClassName={'assistance-form__submit-input'}
      inputRole='submit'
      {...props}
    />
  </div>
);

const AssistanceFormFieldError = ({ message = null }) =>
  message && <span className='assistance-form__field-error'>{message}</span>;

const transformFormData = ({ data, formType = '', captcha, customerType }) => {
  const deviceData = parser(window?.navigator.userAgent);
  const calculateDevice = () => {
    const os = deviceData.os.name;
    const deviceType = deviceData.device.type;
    return os && deviceType ? `${deviceType} ${os}` : 'Sconosciuto';
  };

  const dataToSubmit = {
    formData: {
      ...data,
      Gen_KEY: formType,
      Gen_CodiceTipo: `Problemi_informatici|${formType}`,
      Gen_Stato: 'aperto',
      Gen_Browser:
        parser(window?.navigator.userAgent)?.browser?.name || 'Sconosciuto',
      Gen_Dispositivo: calculateDevice(),
      Gen_TipoCliente: customerType,
    },
    tipology: 'assistance-form',
    captcha,
  };

  return dataToSubmit;
};

// HOC to abstract api call at form submit
const withSubmit = (Form) => {
  const WithSubmit = ({
    formType,
    setShowInfoMessage,
    setDumpedFormState,
    setSpinner,
    ...otherProps
  }) => {
    const [captcha, setCaptcha] = useState('');

    const onSubmit = async (data, customerType) => {
      setSpinner(true);
      window?.scrollTo({ top: 0, behavior: 'smooth' });
      const dataToSend = transformFormData({
        data,
        formType,
        captcha,
        customerType,
      });
      let response;
      try {
        response = await fetch(
          `${process.env.GATSBY_SERVICE_API_URL}/headless-api/orchestrator`,
          {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: {
              Accept: '*',
              'Content-Type': 'application/json',
            },
          }
        );
      } catch (e) {
        setSpinner(false);
        setShowInfoMessage('error');
        setDumpedFormState(data);
      }
      if (!response.ok) {
        setSpinner(false);
        setShowInfoMessage('error');
        setDumpedFormState(data);
        return;
      }
      setSpinner(false);
      setShowInfoMessage('success');
    };

    return (
      <Form
        onSubmit={onSubmit}
        captcha={captcha}
        setCaptcha={setCaptcha}
        {...otherProps}
      ></Form>
    );
  };

  return WithSubmit;
};

export {
  // constants
  browsersOptions,
  devicesOptions,
  personTypes,
  generaliFormFields,
  // functions
  transformFormData,
  // Components
  SubmitInput,
  AssistanceFormFieldError,
  withSubmit,
  // Hooks
};
