import React from 'react';
import classNames from 'classnames';
import { personTypes } from './helpers';
import './SelectForm.scss';

const SelectForm = ({ personType, setPersonType, setDumpedFormState }) => {
  return (
    <fieldset className='assistance-form__select-form select-form'>
      <span className='select-form__description'>Tipo cliente:</span>
      <div className='select-form__fields'>
        <div
          className='select-form__field select-form__physical-person'
          onClick={() => {
            setPersonType(personTypes.PHYSICAL_PERSON);
            setDumpedFormState({});
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setPersonType(personTypes.PHYSICAL_PERSON);
              setDumpedFormState({});
            }
          }}
          role='button'
          tabIndex='0'
        >
          <div
            className={classNames('select-form__checker', {
              'select-form__checker--active':
                personType === personTypes.PHYSICAL_PERSON,
            })}
          ></div>
          <label className='select-form__physical-person-label'>
            Persona fisica
            <input
              className='select-form__input  select-form__physical-person-input'
              type='radio'
              name='personType'
              value={personTypes.PHYSICAL_PERSON}
              checked={personType === personTypes.PHYSICAL_PERSON}
            ></input>
          </label>
        </div>
        <div
          className='select-form__field select-form__legal-person'
          onClick={() => {
            setPersonType(personTypes.LEGAL_PERSON);
            setDumpedFormState({});
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              setPersonType(personTypes.LEGAL_PERSON);
              setDumpedFormState({});
            }
          }}
          role='button'
          tabIndex='0'
        >
          <div
            className={classNames('select-form__checker', {
              'select-form__checker--active':
                personType === personTypes.LEGAL_PERSON,
            })}
          ></div>
          <label>
            Persona giuridica
            <input
              className='select-form__input select-form__legal-person-input'
              type='radio'
              name='personType'
              value={personTypes.LEGAL_PERSON}
              checked={personType === personTypes.LEGAL_PERSON}
              //onChange={() => setPersonType(personTypes.LEGAL_PERSON)}
            ></input>
          </label>
        </div>
      </div>
    </fieldset>
  );
};

export default SelectForm;
