import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import AssistanceForm from '../organisms/assistance-form/AssistanceForm';
import Breadcrumb from '../organisms/breadcrumb/Breadcrumb';
import Layout from '../organisms/layout';
import Seo from '../organisms/seo';
import { getSEOValue } from '../utils/functions';

const PageAssistanceForm = ({
  data: { liferayModuleDetailAssistanceForm },
}) => {
  const formType = liferayModuleDetailAssistanceForm.formType?.value[0] || '';
  const pageSettings = {
    showBreadcrumb: liferayModuleDetailAssistanceForm?.showBreadcrumb?.value,
    showHomePage:
      liferayModuleDetailAssistanceForm?.showBreadcrumb?.content?.showHomePage
        ?.value,
  };

  const pageSEOSettings = getSEOValue(
    liferayModuleDetailAssistanceForm.detailPageSeo
  );

  // TODO: extract in custom hook
  const breadcrumbs = useMemo(() => {
    const homePage = { label: 'Home', link: '/' };
    const breadcrumbsTemp = liferayModuleDetailAssistanceForm?.breadcrumbs
      .filter((page) => page.link !== '')
      .map((page) => ({
        label: page.pageTitle?.value || page.detailPageTitle?.value,
        link: page.link,
      }))
      .concat({
        label: liferayModuleDetailAssistanceForm.detailPageTitle?.value,
      });
    if (pageSettings.showHomePage) {
      breadcrumbsTemp.unshift(homePage);
    }
    return breadcrumbsTemp;
  }, [liferayModuleDetailAssistanceForm, pageSettings.showHomePage]);

  return (
    <Layout>
      <Seo
        title={
          pageSEOSettings.ogTitle ||
          liferayModuleDetailAssistanceForm.detailPageTitle.value
        }
        description={pageSEOSettings.ogDescription}
        image={pageSEOSettings.ogImage}
        link={pageSEOSettings.ogUrl || liferayModuleDetailAssistanceForm.link}
        type={pageSEOSettings.ogType}
        scripts={pageSettings.scripts}
        jsonLd={pageSEOSettings.structuredDataJsonLd}
      />
      {pageSettings.showBreadcrumb && breadcrumbs.length && (
        <Breadcrumb items={breadcrumbs}></Breadcrumb>
      )}
      <AssistanceForm
        privacyPolicyDocumentUrl={
          liferayModuleDetailAssistanceForm?.privacyPolicyDocument?.node
            ?.contentUrl
        }
        formType={formType}
        formTypeDescription={
          liferayModuleDetailAssistanceForm?.formTypeIntroduction?.content
            ?.formTypeDescription?.value
        }
        formTypeIntroduction={
          liferayModuleDetailAssistanceForm?.formTypeIntroduction?.value
        }
        subtitle={
          liferayModuleDetailAssistanceForm?.title?.content?.subtitle?.value
        }
        title={liferayModuleDetailAssistanceForm?.title?.value}
      />
    </Layout>
  );
};

export default PageAssistanceForm;
export const query = graphql`
  query ($id: String) {
    liferayModuleDetailAssistanceForm(id: { eq: $id }) {
      showChangeLanguageButton {
        value
      }
      showBreadcrumb {
        value
        content {
          showHomePage {
            value
          }
        }
      }
      breadcrumbs {
        ... on LiferayPageGeneric {
          pageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailProduct {
          detailPageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailBlog {
          detailPageTitle {
            value
          }
          link
        }
        ... on LiferayModuleDetailIniziative {
          detailPageTitle {
            value
          }
          link
        }
      }
      detailPageSeo {
        value {
          ... on LiferaySubmoduleSeo {
            ogTitle {
              value
            }
            ogType {
              value
            }
            ogDescription {
              value
            }
            ogImageData {
              node {
                publicURL
              }
            }
            ogUrl {
              value
            }
            structuredDataJsonLd {
              value
            }
          }
        }
      }
      detailPageTitle {
        value
      }
      formType {
        value
      }
      formTypeIntroduction {
        content {
          formTypeDescription {
            value
          }
        }
        value
      }
      slug {
        value
      }
      link
      title {
        content {
          subtitle {
            value
          }
        }
        value
      }
      privacyPolicyDocument {
        node {
          contentUrl
        }
      }
    }
  }
`;
