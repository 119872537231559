import React from 'react';

const JsonLd = ({ data, ...other }) => {
  if (!data) return null;
  return (
    <script
      type='application/ld+json'
      {...other}
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
    />
  );
};

export default React.memo(JsonLd);
