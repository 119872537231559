import classNames from 'classnames';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useController } from 'react-hook-form';
import Select from 'react-select';

import {
  generaliFormFields,
  AssistanceFormFieldError,
  personTypes,
} from './helpers';
import { provinceOptions } from '../../utils/provinces';
import {
  capRegexp,
  fiscalCodeOrPartitaIvaRegex,
  fiscalCodeRegex,
  phoneNumberRegex,
} from '../../utils/regexp';
import Chevron from '../../atoms/static-icons/chevron';
import FormDisclaimer from '../../molecules/form-discaimer/form-disclaimer';

const GOOGLE_RECAPTCHA_KEY = process.env.GATSBY_GOOGLE_RECAPTCHA_KEY;

const FormCommonFields = ({
  register,
  setCaptcha,
  formControl,
  privacyPolicyDocumentUrl = '',
  errors = {},
  personType,
  defaultProvinceValue,
}) => {
  const [selectInvalid, setSelectInvalid] = useState(false);
  const { field: provinceField } = useController({
    control: formControl,
    name: generaliFormFields.Gen_Provincia,
  });

  return (
    <>
      <div
        className={classNames(
          `assistance-form__field assistance-form__business-name`
        )}
      >
        <label
          className='assistance-form__field-label'
          htmlFor={generaliFormFields.Gen_CodCliente}
        >
          Codice cliente
        </label>
        <input
          maxLength={255}
          placeholder='Codice cliente'
          className='assistance-form__field-input'
          id={generaliFormFields.Gen_CodCliente}
          {...register(generaliFormFields.Gen_CodCliente)}
        ></input>
      </div>
      <div className={classNames(`assistance-form__field assistance-form__cf`)}>
        <label
          className='assistance-form__field-label'
          htmlFor={generaliFormFields.Gen_CodFiscale}
        >
          Codice fiscale*
        </label>
        <input
          required
          minLength={11}
          maxLength={16}
          placeholder='Codice fiscale'
          className={classNames('assistance-form__field-input', {
            'assistance-form__field-input--invalid':
              errors[generaliFormFields.Gen_CodFiscale]?.message,
          })}
          id={generaliFormFields.Gen_CodFiscale}
          {...register(generaliFormFields.Gen_CodFiscale, {
            required: 'Campo obbligatorio',
            pattern: {
              value:
                personType === personTypes.PHYSICAL_PERSON
                  ? fiscalCodeRegex
                  : fiscalCodeOrPartitaIvaRegex,
              message: 'Formato codice fiscale non corretto',
            },
          })}
        ></input>
        <AssistanceFormFieldError
          message={errors[generaliFormFields.Gen_CodFiscale]?.message}
        />
      </div>
      <div
        className={classNames(
          `assistance-form__field assistance-form__policy-number`
        )}
      >
        <label
          className='assistance-form__field-label'
          htmlFor={generaliFormFields.Gen_NumPolizza}
        >
          Numero di polizza*
        </label>
        <input
          required
          maxLength={255}
          placeholder='Numero di polizza'
          className={classNames('assistance-form__field-input', {
            'assistance-form__field-input--invalid':
              errors[generaliFormFields.Gen_NumPolizza]?.message,
          })}
          id={generaliFormFields.Gen_NumPolizza}
          {...register(generaliFormFields.Gen_NumPolizza, {
            required: 'Campo obbligatorio',
          })}
        ></input>
        <AssistanceFormFieldError
          message={errors[generaliFormFields.Gen_NumPolizza]?.message}
        />
      </div>
      <div
        className={classNames(`assistance-form__field assistance-form__email`)}
      >
        <label
          className='assistance-form__field-label'
          htmlFor={generaliFormFields.Gen_Email}
        >
          E-mail*
        </label>
        <input
          required
          minLength={1}
          maxLength={255}
          placeholder='E-mail'
          className={classNames('assistance-form__field-input', {
            'assistance-form__field-input--invalid':
              errors[generaliFormFields.Gen_Email]?.message,
          })}
          type='email'
          id={generaliFormFields.Gen_Email}
          {...register(generaliFormFields.Gen_Email, {
            required: 'Campo obbligatorio',
            pattern: {
              value: /^\S+@\S+$/i,
              message: 'Inserisci un formato di email valido',
            },
          })}
        ></input>
        <AssistanceFormFieldError
          message={errors[generaliFormFields.Gen_Email]?.message}
        />
      </div>
      <div
        className={classNames(`assistance-form__field assistance-form__phone`)}
      >
        <label
          className='assistance-form__field-label'
          htmlFor={generaliFormFields.Gen_Telefono}
        >
          Telefono*
        </label>
        <input
          required
          minLength={1}
          maxLength={255}
          placeholder='Telefono'
          className={classNames('assistance-form__field-input', {
            'assistance-form__field-input--invalid':
              errors[generaliFormFields.Gen_Telefono]?.message,
          })}
          type='tel'
          id={generaliFormFields.Gen_Telefono}
          {...register(generaliFormFields.Gen_Telefono, {
            required: 'Campo obbligatorio',
            pattern: {
              value: phoneNumberRegex,
              message: 'Inserisci un numero di telefono valido',
            },
          })}
        ></input>
        <AssistanceFormFieldError
          message={errors[generaliFormFields.Gen_Telefono]?.message}
        />
      </div>
      <div
        className={classNames(
          `assistance-form__field assistance-form__address`
        )}
      >
        <label
          className='assistance-form__field-label'
          htmlFor={generaliFormFields.Gen_Indirizzo}
        >
          Indirizzo*
        </label>
        <input
          required
          maxLength={255}
          placeholder='Indirizzo'
          className='assistance-form__field-input'
          id={generaliFormFields.Gen_Indirizzo}
          {...register(generaliFormFields.Gen_Indirizzo, {
            required: 'Campo obbligatorio',
          })}
        ></input>
      </div>
      <div
        className={classNames(`assistance-form__field assistance-form__town`)}
      >
        <label
          className='assistance-form__field-label'
          htmlFor={generaliFormFields.Gen_Citta}
        >
          Città*
        </label>
        <input
          required
          maxLength={255}
          placeholder='Città'
          className={classNames('assistance-form__field-input', {
            'assistance-form__field-input--invalid':
              errors[generaliFormFields.Gen_Citta]?.message,
          })}
          id={generaliFormFields.Gen_Citta}
          {...register(generaliFormFields.Gen_Citta, {
            required: 'Campo obbligatorio',
          })}
        ></input>
        <AssistanceFormFieldError
          message={errors[generaliFormFields.Gen_Citta]?.message}
        />
      </div>
      <div
        className={classNames(
          `assistance-form__field assistance-form__province`
        )}
      >
        <label className='assistance-form__field-label' htmlFor='Gen_Provincia'>
          Provincia*
        </label>
        <Select
          components={{
            DropdownIndicator: Chevron,
          }}
          defaultInputValue={defaultProvinceValue}
          classNamePrefix='select'
          className={classNames('assistance-form__field-input', {
            'assistance-form__field-input--invalid': selectInvalid,
          })}
          id={generaliFormFields.Gen_Provincia}
          placeholder='Provincia'
          options={provinceOptions}
          onChange={(option) => {
            setSelectInvalid(false);
            provinceField.onChange(option.value);
          }}
          onBlur={() => !provinceField.value && setSelectInvalid(true)}
        ></Select>
        {selectInvalid && (
          <AssistanceFormFieldError message={'Campo obbligatorio'} />
        )}
      </div>

      <div
        className={classNames(`assistance-form__field assistance-form__cap`)}
      >
        <label
          className='assistance-form__field-label'
          htmlFor={generaliFormFields.Gen_Cap}
        >
          CAP*
        </label>
        <input
          required
          maxLength={5}
          placeholder='CAP'
          className={classNames('assistance-form__field-input', {
            'assistance-form__field-input--invalid':
              errors[generaliFormFields.Gen_Cap]?.message,
          })}
          id={generaliFormFields.Gen_Cap}
          {...register(generaliFormFields.Gen_Cap, {
            required: 'Campo obbligatorio',
            pattern: {
              value: capRegexp,
              message: 'Inserisci un formato di CAP valido',
            },
          })}
        ></input>
        <AssistanceFormFieldError
          message={errors[generaliFormFields.Gen_Cap]?.message}
        />
      </div>
      <div
        className={classNames(`assistance-form__field assistance-form__info`)}
      >
        <label
          className='assistance-form__field-label'
          htmlFor={generaliFormFields.Gen_Info}
        >
          Maggiori informazioni*
        </label>
        <textarea
          required
          className={classNames('assistance-form__field-textarea', {
            'assistance-form__field-textarea--invalid':
              errors[generaliFormFields.Gen_Info]?.message,
          })}
          id={generaliFormFields.Gen_Info}
          {...register(generaliFormFields.Gen_Info, {
            required: 'Campo obbligatorio',
          })}
        ></textarea>
        <AssistanceFormFieldError
          message={errors[generaliFormFields.Gen_Info]?.message}
        />
      </div>

      <FormDisclaimer
        buttonName='Richiedi assistenza'
        className='assistance-form__privacy'
        privacyPolicyDocumentUrl={privacyPolicyDocumentUrl}
        showPrivacyInfo
      />
      <ReCAPTCHA
        className='assistance-form__recaptcha'
        sitekey={GOOGLE_RECAPTCHA_KEY}
        onChange={setCaptcha}
      />
    </>
  );
};

export default FormCommonFields;
