import React, { Fragment, useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from '../link';
import JsonLd from '../../atoms/json-ld';
import './BreadCrumb.scss';

const generateMetadata = (items, siteUrl) => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: items.map((item, i) => ({
    '@type': 'ListItem',
    position: i + 1,
    item: {
      '@type': 'Thing',
      '@id': item.link ? `${siteUrl}${item.link}` : item.link,
      name: item.label,
    },
  })),
});

const Breadcrumb = ({ items }) => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  const jsonLdData = useMemo(
    () => generateMetadata(items, site.siteMetadata.siteUrl),
    [items, site]
  );
  return (
    <nav
      data-module-code='C-52'
      data-module-name='breadcrumb'
      className='wrapper'
    >
      <div className='breadcrumb'>
        <ul className='breadcrumb__items'>
          {items &&
            items.map((item, key) => (
              <Fragment key={key}>
                <li
                  key={key}
                  className={`breadcrumb__item ${
                    !item.link ? 'breadcrumb__item--current' : ''
                  }`}
                >
                  {item.link ? (
                    <Link to={item.link}>{item.label}</Link>
                  ) : (
                    <span>{item.label}</span>
                  )}
                  {key < items.length - 1 ? (
                    <em className='breadcrumb__separator'></em>
                  ) : null}
                </li>
              </Fragment>
            ))}
        </ul>
      </div>
      <JsonLd data={jsonLdData} data-search-breadcrumb='' />
    </nav>
  );
};

export default Breadcrumb;
