import React from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';

import FormCommonFields from './FormCommonFields';
import {
  SubmitInput,
  generaliFormFields,
  AssistanceFormFieldError,
  personTypes,
  withSubmit,
} from './helpers';
import { birthDateRegexp } from '../../utils/regexp';

const PhysicalPersonForm = ({
  formType = '',
  privacyPolicyDocumentUrl = '',
  defaultState,
  captcha,
  setCaptcha,
  onSubmit,
}) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'all',
    defaultValues: defaultState,
  });

  return (
    <form
      className='assistance-form__form  assistance-form__physical-person'
      onSubmit={handleSubmit((data) => onSubmit(data, 'Persona fisica'))}
    >
      <div className='assistance-form__field assistance-form__name'>
        <label
          className='assistance-form__field-label'
          htmlFor={generaliFormFields.Gen_Nome}
        >
          Nome*
        </label>
        <input
          required
          minLength={1}
          maxLength={255}
          className={classNames('assistance-form__field-input', {
            'assistance-form__field-input--invalid':
              errors[generaliFormFields.Gen_Nome]?.message,
          })}
          id={generaliFormFields.Gen_Nome}
          placeholder='Nome'
          {...register(generaliFormFields.Gen_Nome, {
            required: 'Campo obbligatorio',
          })}
        ></input>
        <AssistanceFormFieldError
          message={errors[generaliFormFields.Gen_Nome]?.message}
        />
      </div>
      <div className='assistance-form__field assistance-form__surname'>
        <label
          className='assistance-form__field-label'
          htmlFor={generaliFormFields.Gen_Cognome}
        >
          Cognome*
        </label>
        <input
          required
          minLength={1}
          maxLength={255}
          className={classNames('assistance-form__field-input', {
            'assistance-form__field-input--invalid':
              errors[generaliFormFields.Gen_Cognome]?.message,
          })}
          id={generaliFormFields.Gen_Cognome}
          placeholder='Cognome'
          {...register(generaliFormFields.Gen_Cognome, {
            required: 'Campo obbligatorio',
          })}
        ></input>
        <AssistanceFormFieldError
          message={errors[generaliFormFields.Gen_Cognome]?.message}
        />
      </div>
      <div className='assistance-form__field assistance-form__birth-date'>
        <label
          className='assistance-form__field-label'
          htmlFor={generaliFormFields.Gen_DtNascita}
        >
          Data di nascita
        </label>
        <input
          minLength={10}
          maxLength={255}
          placeholder='GG/MM/AAAA'
          className={classNames('assistance-form__field-input', {
            'assistance-form__field-input--invalid':
              errors[generaliFormFields.Gen_DtNascita]?.message,
          })}
          id={generaliFormFields.Gen_DtNascita}
          {...register(generaliFormFields.Gen_DtNascita, {
            pattern: {
              value: birthDateRegexp,
              message: 'Inserisci una data in formato GG/MM/AAAA',
            },
          })}
        ></input>
        <AssistanceFormFieldError
          message={errors[generaliFormFields.Gen_DtNascita]?.message}
        />
      </div>
      <FormCommonFields
        personType={personTypes.PHYSICAL_PERSON}
        privacyPolicyDocumentUrl={privacyPolicyDocumentUrl}
        register={register}
        setCaptcha={setCaptcha}
        formControl={control}
        errors={errors}
        defaultProvinceValue={defaultState[generaliFormFields.Gen_Provincia]}
      />

      {<SubmitInput disabled={!isValid || !captcha} htmlFor={'form-submit'} />}
    </form>
  );
};

export default withSubmit(PhysicalPersonForm);
